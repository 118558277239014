<template>
    <div class="ChangePassword full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <v-form ref="form">
                <div class="content-area pa-5 flex-grow-1 bg-white">
                    <v-row class="d-flex align-center">
                        <v-col class="col-12">
                            <h2>{{ activeUser.username }} – {{ $t('system.userManagement.changePassword') }}</h2>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-4">
                            <p>{{ $t('system.userManagement.oldPassword') }}</p>
                        </v-col>
                        <v-col class="col-8">
                            <text-input inputName="oldpassword" @onInputFocus="onInputFocus" type="password" :rules="[v => !!v || $t('validation.required')]" />
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-4">
                            <p>{{ $t('system.userManagement.newPassword') }}</p>
                        </v-col>
                        <v-col class="col-8">
                            <text-input inputName="password" @onInputFocus="onInputFocus" type="password" :rules="[v => !!v || $t('validation.required'), rules.maxLengthValue, rules.alphanumericSpecCharValue, rules.passwordSpecialChar]"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-4">
                            <p>{{ $t('system.userManagement.passwordRepeat') }}</p>
                        </v-col>
                        <v-col class="col-8">
                            <text-input inputName="passwordrepeat" @onInputFocus="onInputFocus" type="password" :rules="[v => !!v || $t('validation.required'), rules.passwordRepeat]" />
                        </v-col>
                    </v-row>
                </div>
            </v-form>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button buttonFunction="cancel" @footerButtonClick="goBack"></footer-button>
            <footer-button buttonFunction="save" :successCheck="showSaveSuccess" :progress="showSaveProgress" :class="{'disabled': saveDisabled || !checkUserRights('mediaEdit')}" @footerButtonClick="save"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import TextInput from '@/components/TextInput.vue'
import FooterButton from '@/components/FooterButton.vue'

import {mapState} from "vuex";
import mixins from "@/mixins/mixins";
import router from "@/router/router";

export default {
    name: 'ChangePassword',
    components: {
        TextInput,
        FooterButton,
    },
    props: {
    },
    data () {
        return {
            showSaveProgress: false,
            showSaveSuccess: false,
            rules: {
                maxLengthValue: v => {
                    if (v){
                        return v.length < 24 || `${this.$t('validation.chooseShorterValue')}`;
                    } else return true;
                },
                alphanumericSpecCharValue: v => {
                    if (v){
                        let alphanumericSpecChar = /^[A-Za-z0-9*%&\-_/+?!@#.]+$/;
                        return alphanumericSpecChar.test(v) || `${this.$t('validation.pleaseEnterValidValue')}`;
                    } else return true;
                },
                passwordSpecialChar: v => {
                    if (v){
                        if (this.activeUser.groupId != null){
                            //get group settings of selected group
                            let group = this.grouplist.find(group => {
                                return group.id == this.activeUser.groupId;
                            });
                            //check if complexPassword activated
                            if (group.complexPassword){
                                let specialChar = /[*%&\-_/+?!@#.]/;
                                let capitalChar = /[A-Z]/;
                                let number = /[0-9]/;
                                if (specialChar.test(v) && capitalChar.test(v) && number.test(v) && (v.length >= 8)){
                                    return true;
                                } else return `${this.$t('validation.complexPassword')}`;
                            } else return true;
                        } else {
                            return this.$t('validation.groupSelectionMissing');
                        }
                    } else return true;
                },
                passwordRepeat: v => {
                    if (v){
                        if (this.password === this.passwordrepeat && this.passwordrepeat != null){
                            return true;
                        } else {
                            return `${this.$t('login.passwordNotMatching')}`;
                        }
                    } else return true;
                }
            }
        }
    },
    computed: {
        ...mapState([
            'activeUser',
            'grouplist',
            'password',
            'passwordrepeat',
            'oldpassword',
        ]),
        saveDisabled(){
            if (this.password && this.passwordrepeat){
                return false;
            } else {
                return true;
            }
        }
    },
    methods:{
        goBack(){
            this.$router.go(-1);
        },
        save(){
            this.rules.passwordRepeat =
            this.$refs.form.validate();
            if (this.$refs.form.validate()){
                this.showSaveProgress = true;
                let Password = {};
                Password.id = this.activeUser.id;
                Password.oldPassword = this.oldpassword;
                Password.newPassword = this.password;
                this.$store.dispatch('postAxiosNoSetter', ['admin/user/passwordChange', Password])
                .then(response=>{
                    this.showSaveProgress = false;
                    if (response.status === 200){
                        this.showSaveSuccess = true;
                        setTimeout(()=>{
                            this.showSaveSuccess = false;
                            this.$store.commit('emptyPassword');
                            router.push('/stations-overview');
                        }, 800)
                    } else {
                        this.$store.commit('emptyPassword');
                    }
                })
            }
        },
        // Keyboard functions ------------------ //
        onInputFocus(input, onlyNumbers) {
            this.$emit('onInputFocus', [input.target.id, onlyNumbers]);
            this.$vuetify.goTo('#'+input.target.id, {duration: 100, offset: 80, container: ".content-area"});
        },
    },
    mixins: [mixins],
    beforeCreate (){
        this.$store.dispatch('getAxiosSetter', ['/admin/group/list', 'grouplist']);
        this.$store.commit('emptyPassword');
    },
    created() {
    },
    beforeRouteLeave (to, from, next) {
        if(this.saveDisabled){
            next()
        } else {
            this.$confirm({message: this.$t('alerts.unsavedChanges'), show: true, cancelText: this.$t('general.cancel'), continueText: this.$t('general.continue') })
                .then(() => {
                    // continue
                    next();
                })
                .catch(() => {
                    // cancel
                });
        }
    },
}
</script>
<style scoped lang="scss">
</style>